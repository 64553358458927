<template>
  <div>
    <base-header class="pb-6" type="">
      <div class="row align-items-center">
        <div class="col-lg-6 col-7">
          <!-- <h6 class="h2 d-inline-block mb-0">{{ $t("COMMON.DASHBOARD") }}</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links">
              <li class="breadcrumb-item">
                <router-link :to="'/'"><i class="fas fa-home"></i></router-link>
              </li>
            </ol>
          </nav> -->
        </div>
      </div>
    </base-header>
  </div>
</template>
<script>
// Components
import BaseHeader from "@/components/BaseHeader";

export default {
  components: {
    BaseHeader,
  },
  data() {
    return {};
  },
};
</script>
<style></style>
